import request from '@/tool/axios'

// 商户列表
export function list(params) {
    return request({
        url: '/v1/tenantuser/myboundtenants',
        method:'get',
        params
    })
}

// 登录
export function login(data,headers) {
    return request({
        url: '/v1/tenantuser/loginbywechat',
        headers,
        data
    })
}

<template>
  <div class="bg">
    <div class="top font-28">
      <p class="m-b-8 font-36 bold">{{ tenant.tenantName }}</p>
      <p>{{ tenant.userName }}</p>
      <p class="change" @click="change">切换<van-icon name="arrow" /></p>
    </div>
    <div class="flex-start-center font-26 box">
      <div class="item" @click="toWriteOff">
        <img src="@/assets/images/index/writeOff.png" class="icon">
        <p>核销</p>
      </div>
      <div class="item" @click="toOrder">
        <img src="@/assets/images/index/order.png" class="icon">
        <p>日历房订单</p>
      </div>
      <div class="item" @click="toRoom">
        <img src="@/assets/images/index/room.png" class="icon">
        <p>房态控制</p>
      </div>
    </div>
  </div>
</template>

<script>
import { login } from '@/api/tenant'
import { setToken, getToken, getTenant, reomveTenant, removeToken } from '@/tool/cookie'
export default {
  data() {
    return {
      tenant: {}
    }
  },
  mounted() {
    this.tenant = getTenant() || {}
    this.autoLogin()
  },
  methods: {
    // 自动登录
    autoLogin() {
      if (getToken()) {
        return
      }
      const { code, targetUrl } = this.$route.query
      login({
        userId: this.tenant.userId,
        wechatCode: code
      }, { Tenant: this.tenant.tenantId }).then(res => {
        setToken(res.accessToken)
        if (targetUrl) {
          if (targetUrl.startsWith('http')) {
            window.location.href = targetUrl
          } else {
            this.$router.push(decodeURIComponent(targetUrl))
          }
        }
      })
    },
    // 切换供应商
    change() {
      reomveTenant()
      removeToken()
      this.$router.push('/')
    },
    toWriteOff() {
      window.location = window.location.protocol + '//' + window.location.host + '/writeOffRecord'
    },
    toOrder() {
      this.$router.push('/calendarRoomOrder')
    },
    toRoom() {
      this.$router.push('/roomStateControl')
    }
  }
}
</script>

<style lang="scss" scoped>
.bg {
  background-image: url(~@/assets/images/index/bg.png);
  background-size: 100% 286px;
  background-repeat: no-repeat;
  background-position: top center;
  .top {
    padding-top: 45px;
    padding-left: 30px;
    padding-bottom: 38px;
    color: #ffffff;
    position: relative;
    .change{
      position: absolute;
      right: 36px;
      top: 45px;
    }
  }
  .box {
    padding-left: 30px;
    flex-wrap: wrap;
    .item {
      width: 337px;
      height: 178px;
      background: #ffffff;
      box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.08);
      border-radius: 18px;
      text-align: center;
      margin-right: 22px;
      margin-bottom: 22px;
      .icon {
        width: 66px;
        height: 66px;
        margin-top: 40px;
        margin-bottom: 14px;
      }
    }
  }
}
</style>
